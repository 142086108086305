/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import { AUTH_METHOD, COOKIE_CONSENT, ORG_ID, USERNAME } from '@constants/cookieConstants';

export const getAllCookies = (): {
  [AUTH_METHOD]?: string,
  [COOKIE_CONSENT]?: string,
  [ORG_ID]?: string,
  [USERNAME]?: string
} =>
  decodeURIComponent(document.cookie)
    .split(';')
    .map((cookie) => cookie.trim().split('='))
    .reduce((acc, [key, value]) => ({ [key]: value, ...acc }), {});

export const getCookie = (cookieName: string) => {
  let cookieValue = null;
  decodeURIComponent(document.cookie)
    .split(';')
    .find((cookie) => {
      const [ name, value ] = cookie.trim().split('=');
      if (name === cookieName) {
        cookieValue = value;
        return true;
      }
      return false;
    });
  return cookieValue;
};

export const setCookie = (cookieName: string, cookieValue: string, daysToExpire: number) => {
  let cookieString = `${cookieName}=${cookieValue}`;
  if (daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    cookieString = cookieString.concat(`; expires=${date.toUTCString()}`);
  }
  document.cookie = cookieString;
};

export const deleteCookie = (cookieName: string) => {
  if (getCookie(cookieName)) {
    const date = new Date().toUTCString();
    document.cookie = `${cookieName}=; expires=${date}`;
  }
};
