/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import enStrings from '@locale/en';

const getLocale = () => {
  // todo: write code to actually handle multiple options
  let language = (navigator.languages && navigator.languages[0]) || navigator.language;
  language = 'en'; // hardcode to only supported option today
  let localeStrings: typeof enStrings | undefined = enStrings;
  try {
    if (localStorage.getItem('language')) {
      if (localStorage.getItem('language') === 'ids') {
        localeStrings = undefined;
      } else if (localStorage.getItem('language') === 'double') {
        for (const [key, value] of Object.entries(localeStrings)) {
          // @ts-ignore
          localeStrings[key] = value + ' ' + value;
        }
      } else {
        language = localStorage.getItem('language') || 'en';
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { language, localeStrings };
};

export default getLocale;
