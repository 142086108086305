/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';

import { useIntl, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';

import Plus from '@art/Plus.svg';

const RedirectToSignUpPage = () => {
  const { formatMessage } = useIntl();

  const handleSignUp = () => {
    window.location.assign(
      `${window.location.origin.replace('login.', 'app.')}/signup`
    );
  };

  const renderSignUpButton = () => (
    <Grid
      container
      classes={{ root: 'link margin-top--double text-small' }}
      alignItems='center'
      onClick={handleSignUp}
    >
      <img
        src={Plus}
        className='login__icon margin-right--half'
        aria-label='Add'
        alt={formatMessage({ id: 'Common.Add' })}
      />
      <FormattedMessage id='Login.Lables.SignUp' />
    </Grid>
  );

  return (
    <Grid container justify='flex-start'>
      <Grid item>
        {renderSignUpButton()}
      </Grid>
    </Grid>
  );
};

export default RedirectToSignUpPage;
