/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

import apiUI from './apiUI';

export const getInfo = (email: string) => {
  const options = { params: { email } };
  return apiUI.get('login/info', options).then(({ data }) => data);
};

export default {
  getInfo
};
