/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

export default {
  "Common.Add": "Add",
  "Common.Back": "Arrow Right",
  "Common.Cancel": "Cancel",
  "Common.Save": "Save",
  "Common.Loading": "Loading",
  "Common.Logo.Alt": "Dremio Narwhal Logo",
  "Login.Title": "Welcome to Dremio. Please enter your email to continue.",
  "Login.OrganizationTitle":
    "Welcome to Dremio. Please choose your organization.",
  "Login.Organization": "Welcome to {orgName}. Log in to continue.",
  "Login.OrgNotFoundPage.Title":
    "Your email is not associated with an organization.",
  "Login.SignUpRedirectPage.Title":
    "Your email is not associated with an organization.",
  "Login.PrivacyPolicy": "Privacy Policy",
  "Login.PrivacyPolicyLink": "https://www.dremio.com/legal/privacy-policy/",
  "Login.TermsOfService": "Terms of Service",
  "Login.TermsOfServiceLink": "https://www.dremio.com/legal/terms-of-service",
  "Login.Error.EmailRequired": "Required",
  "Login.Error.EmailValidation": "Invalid email address",
  "Login.Error.Email":
    "Something went wrong. Please ensure you entered a valid email address.",
  "Login.Error.MissingRedirect":
    "Something went wrong. The redirect link is missing.", // Placeholder
  "Login.Error.OrgNotFound":
    "The user ID or password was incorrect. Ensure you have selected the correct organization.",
  "Login.Labels.Email": "Email",
  "Login.Lables.SignUp": "Sign up for a Dremio organization",
  "Login.Labels.ContinueButton": "Continue",
  "Login.Labels.OrgAndAuthItem": "{orgName} | Log in with {authMethod}",
  "Login.Labels.ChangeEmail":
    "Wrong email address? <login>Log in </login> or <signup>sign up</signup> with a different email address.",
  "Login.Labels.ChangeOrg":
    "Wrong organization? Go back to <l>choose a different organization</l>.",
  "Login.SocialSignIn.ButtonText": " Log in with {social}",
  "Cookie.Consent.Description":
    "Our site uses cookies that help us to improve experience browsing our site. By clicking “Accept all”, you agree to the use of all cookies. You can opt-out or change your settings by clicking on “Manage settings”. To learn more, see <l>Privacy Policy</l>.",
  "Cookie.Consent.ManageSetting": "Manage settings",
  "Cookie.Consent.Accept": "Accept all",
  "Cookie.Preferences.Title": "Manage Cookie Preferences",
  "Cookie.Preferences.Description":
    "We use cookies and similar tools (collectively, “cookies”) for the following purposes.",
  "Cookie.Preferences.CookiePreferences": "Cookies preference",
  "Cookie.Preferences.Form.Strict.Label": "Strictly Necessary Cookies",
  "Cookie.Preferences.Form.Strict.Description":
    "Essential cookies are necessary to provide our site and services and cannot be deactivated. They are usually set in response to your actions on the site, such as setting your privacy preferences, signing in, or filling in forms.",
  "Cookie.Preferences.Form.Functional.Label": "Functional Cookies",
  "Cookie.Preferences.Form.Functional.Description":
    "Functional cookies help us provide useful site features, remember your preferences, and display relevant content. Approved third parties may set these cookies to provide certain site features. If you do not allow these cookies, then some or all of these services may not function properly.",
};
