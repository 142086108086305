/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';
import { LOCAL, DISPLAY_NAME } from '@constants/socialConstants';

import SocialSignInButton from '../SocialSignInButton';

type SocialAndEnterpriseForm = {
  authMethods: Array<keyof typeof DISPLAY_NAME | typeof LOCAL>;
  handleClick: (authMethod: string) => string | void;
  isHrefComponent: boolean;
  orgId: string;
};

const SocialAndEnterpriseForm = (props: SocialAndEnterpriseForm) => {
  const {
    authMethods,
    handleClick,
    ...rest
  } = props;
  // @ts-ignore
  const socialAndEnterpriseMethods: Array<keyof typeof DISPLAY_NAME> = authMethods.filter(authMethod => authMethod !== LOCAL);
  return (
    <div>
      {
        socialAndEnterpriseMethods.map(authMethod => (
          <SocialSignInButton
            social={authMethod}
            socialLogin={handleClick}
            className='margin-bottom--double'
            key={`${authMethod}-signin-button`}
            {...rest}
          />
        ))
      }
    </div>
  );
};

export default SocialAndEnterpriseForm;
