/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import {
  GITHUB,
  GOOGLE,
  MICROSOFT,
  AZURE_AD,
  OKTA,
  ICONS,
  DISPLAY_NAME
} from '@constants/socialConstants';

import './SocialSignInButton.scss';

type SocialSignInButtonProps = {
  className: string;
  isHrefComponent: boolean;
  social: keyof typeof ICONS | keyof typeof DISPLAY_NAME;
  socialLogin: (social: string) => string | void;
}

export const SocialSignInButton = (props: SocialSignInButtonProps) => {
  const {
    isHrefComponent,
    social,
    socialLogin: dispatchSocialLogin,
    className
  } = props;

  const handleClick = () => {
    dispatchSocialLogin(social);
  };

  const buttonText = <FormattedMessage
    id='Login.SocialSignIn.ButtonText'
    defaultMessage={`Log in with ${DISPLAY_NAME[social]}`}
    values={{ social: DISPLAY_NAME[social] }}
  />;

  const rootClassName = clsx(
    'socialSigninButton',
    { 'socialSigninButton__google': social === GOOGLE },
    { 'socialSigninButton__github': social === GITHUB },
    { 'socialSigninButton__microsoft': social === MICROSOFT },
    { 'socialSigninButton__azuread': social === AZURE_AD },
    { 'socialSigninButton__okta': social === OKTA },
    className
  );

  const buttonIcon = () => (
    <img
      src={ICONS[social]}
      alt={DISPLAY_NAME[social]}
      aria-label='Dremio Narwhal Logo'
    />
  );

  const getHrefURL = () => {
    return dispatchSocialLogin(social);
  };

  const renderSocialButtons = () => (
    <div
      className={rootClassName}
      onClick={handleClick}
      role='button'
      data-qa={`${social}-signin`}
    >
      <div className='socialSigninButton__icon'>
        {buttonIcon()}
      </div>
      <div data-qa='social-login-text' className='socialSigninButton__text'>
        {buttonText}
      </div>
    </div>
  );

  const renderSocialButtonsWithHref = () => (
    <a
      className={rootClassName}
      role='button'
      // @ts-ignore
      href={getHrefURL()}
      data-qa={`${social}-signin`}
    >
      <div className='socialSigninButton__icon'>
        {buttonIcon()}
      </div>
      <div data-qa='social-login-text' className='socialSigninButton__text'>
        {buttonText}
      </div>
    </a>
  );

  return (
    <>
      { isHrefComponent ? renderSocialButtonsWithHref() : renderSocialButtons()}
    </>
  );
};

SocialSignInButton.propTypes = {
  socialLogin: PropTypes.func.isRequired,
  social: PropTypes.oneOf([
    GITHUB,
    GOOGLE,
    MICROSOFT,
    AZURE_AD,
    OKTA
  ]).isRequired,
  className: PropTypes.string,
  isHrefComponent: PropTypes.bool.isRequired
};

SocialSignInButton.defaultProps = {
  socialLogin: () => {},
  isHrefComponent: false
};

export default SocialSignInButton;
