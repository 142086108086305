/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';

import { useIntl } from 'react-intl';

import ArrowRight from '@art/ArrowRight.svg';

type OrgSelectFormProps = {
  handleOrgSelect: (orgId: string) => void;
  orgList: { orgId: string, orgName: string }[]
}

const OrgSelectForm = (props: OrgSelectFormProps) => {
  const {
    orgList,
    handleOrgSelect
  } = props;
  const { formatMessage } = useIntl();

  const renderOrgAndAuthList = (orgs: { orgId: string, orgName: string }[]) => (
    <div className='login__orgList'>
      { orgs.map(({ orgId, orgName }, index) => renderOrgItemAsLink(orgId, orgName, index === orgs.length - 1))}
    </div>
  );

  const renderOrgItemAsLink = (orgId: string, orgName: string, isLast: boolean) => {
    const key = `${orgId}_${orgName}`;
    return (
      <div
        key={key}
        onClick={() => handleOrgSelect(orgId)}
        className={`login__orgListItem gutter-top gutter-bottom margin-right--half ${isLast ? '--last' : ''}`}
      >
        <span className='gutter-right--triple'>
          { orgName || orgId }
        </span>
        <img
          src={ArrowRight}
          className='login__icon'
          alt={formatMessage({ id: 'Common.Back' })}
        />
      </div>
    );
  };

  return renderOrgAndAuthList(orgList);
};

export default OrgSelectForm;
