/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import axios from 'axios';

import { API_URL } from '@constants/apiConstants';

const apiUI = axios.create({
  baseURL: API_URL
});

export default apiUI;
