/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React, { ReactChild } from 'react';
import { FormattedMessage } from 'react-intl';

type ChangeEmailButtonProps = {
  handleClick: () => void;
}

const ChangeEmailButton = (props: ChangeEmailButtonProps) => {
  const { handleClick } = props;

  const signupLink = `${window.location.origin.replace('login.', 'app.')}/signup`;

  return (
    <div className='margin-top loginPage__navigationLinks'>
      <FormattedMessage id='Login.Labels.ChangeEmail' values={{
        login: (chunks: ReactChild) => <span className='link' onClick={handleClick}>{chunks}</span>, // eslint-disable-line react/display-name
        signup: (chunks: ReactChild) => <a href={signupLink}>{chunks}</a> // eslint-disable-line react/display-name
      }}/>
    </div>
  );
};

export default ChangeEmailButton;
