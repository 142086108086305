/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

import React from "react";
import ReactDOM from "react-dom";
import Root from "@containers/Root";

import "./styles/index.scss";
import "dremio-ui-lib/dist/index.css";
import "dremio-ui-lib/dist-themes/dremio/index.css";

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
