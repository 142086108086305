/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type ChangeOrgButtonProps = {
  handleClick: () => void
}

const ChangeOrgButton = (props: ChangeOrgButtonProps) => {
  const { handleClick } = props;

  return (
    <div className='margin-top loginPage__navigationLinks'>
      <FormattedMessage id='Login.Labels.ChangeOrg' values={{
        l: (chunks: ReactNode) => <span className='link' onClick={handleClick}>{chunks}</span> // eslint-disable-line react/display-name
      }}/>
    </div>
  );
};

export default ChangeOrgButton;
