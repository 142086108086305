/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';

import { IntlProvider } from 'react-intl';

import getLocale from '@utils/getLocale';

import Routes from '../Routes';

const Root  = () => {
  return (
    <IntlProvider locale={getLocale().language} messages={getLocale().localeStrings}>
      <div className='full-height'>
        <Routes />
      </div>
    </IntlProvider>
  );
};

export default Root;
