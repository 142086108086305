/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

// Pages
import LoginPage from '@pages/Login';

const Routes = () => (
  <Router>
    <Route path='/'>
      <Switch>
        <Route path='/' component={LoginPage} />
        <Redirect to='/' />
      </Switch>
    </Route>
  </Router>
);

export default Routes;
