/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React, { useEffect, useState, ReactChild, MouseEvent } from "react";
import { Field, FormikProvider, FormikProps } from "formik";
import { useIntl } from "react-intl";

import {
  Button,
  DialogContent,
  ExternalLink,
  ModalForm,
  ModalFormAction,
  ModalFormActionContainer,
  // @ts-ignore
} from "dremio-ui-lib";
import Switch from "@material-ui/core/Switch";
import { getCookie, setCookie } from "@utils/cookieUtils";

import Close from "@art/CloseSmall.svg";

import { COOKIE_CONSENT } from "@constants/cookieConstants";

import "./cookieConsent.scss";

type CookieConsentProps = {
  showPreferences: boolean;
  setShowPreferences: (preference: boolean) => void;
};

type FormValues = {
  strictCookies?: boolean;
  functionalCookies: boolean;
};

const CookieConsent: React.FC<CookieConsentProps> = (props) => {
  const { showPreferences, setShowPreferences } = props;

  const { formatMessage } = useIntl();

  useEffect(() => {
    const cookieConsent = getCookie(COOKIE_CONSENT);
    if (cookieConsent === null) {
      setShowConsent(true);
    }
  }, []);

  const [showConsent, setShowConsent] = useState(false);

  const handleShowPreferences = () => setShowPreferences(true);

  const handleClosePreferences = () => setShowPreferences(false);

  const handleAcceptAll = () =>
    saveCookiePreferences({ functionalCookies: true });

  const saveCookiePreferences = (values: FormValues) => {
    const { functionalCookies } = values;
    const cookieConsentValue = functionalCookies ? "true" : "false";
    setCookie(COOKIE_CONSENT, cookieConsentValue, 365);
    showPreferences && handleClosePreferences();
    setShowConsent(false);
  };

  const getInitialValues = () => {
    const cookieConsent = getCookie(COOKIE_CONSENT) === "true";
    return {
      functionalCookies: cookieConsent,
      strictCookies: true,
    };
  };

  const renderConsent = () =>
    showConsent ? (
      <div className="cookieConsent">
        <div className="cookieConsent__content gutter-left--double gutter-right--double gutter-top--double">
          <p className="cookieConsent__content--text gutter-top">
            {formatMessage(
              { id: "Cookie.Consent.Description" },
              {
                /* eslint-disable react/display-name */
                l: (chunks: ReactChild) => (
                  <ExternalLink
                    href={formatMessage({ id: "Login.PrivacyPolicyLink" })}
                  >
                    {chunks}
                  </ExternalLink>
                ),
                /* eslint-enable react/display-name */
              }
            )}
          </p>
          <img
            src={Close}
            className="cookieConsent__content--icon"
            onClick={() => setShowConsent(false)}
            alt={formatMessage({ id: "Common.Close" })}
          />
        </div>
        <div className="cookieConsent__footer gutter-right--double">
          <span
            className="link gutter-right--double"
            onClick={handleShowPreferences}
          >
            {formatMessage({ id: "Cookie.Consent.ManageSetting" })}
          </span>
          <Button onClick={handleAcceptAll} color="primary">
            {formatMessage({ id: "Cookie.Consent.Accept" })}
          </Button>
        </div>
      </div>
    ) : null;

  const renderPreferences = () => (
    <ModalForm
      open={showPreferences}
      onClose={handleClosePreferences}
      title={formatMessage({ id: "Cookie.Preferences.Title" })}
      initialValues={getInitialValues()}
      onSubmit={saveCookiePreferences}
      disableUnsavedWarning
      enableReinitialize
      classes={{ paper: "cookiePreferences" }}
      maxWidth="md"
    >
      {(formikProps: FormikProps<FormValues>) => {
        const {
          setFieldValue,
          submitForm,
          initialValues: { functionalCookies: functionalCookieInitialValue },
          values: { functionalCookies: functionalCookiesValue },
        } = formikProps;

        const handleFunctionalCookieToggle = (
          event: MouseEvent<HTMLInputElement>
        ) => {
          event.preventDefault();
          setFieldValue("functionalCookies", !functionalCookiesValue);
        };

        return (
          <FormikProvider value={formikProps}>
            <DialogContent>
              <div className="gutter-top--double gutter-bottom--triple">
                {formatMessage({ id: "Cookie.Preferences.Description" })}
              </div>
              <div className="cookiePreferences__field gutter-bottom--triple">
                <div>
                  {formatMessage({
                    id: "Cookie.Preferences.Form.Strict.Label",
                  })}
                  <div className="cookiePreferences__field__description gutter-top--half">
                    {formatMessage({
                      id: "Cookie.Preferences.Form.Strict.Description",
                    })}
                  </div>
                </div>
                <div>
                  <Field
                    disabled
                    defaultChecked
                    name="strictCookies"
                    color="primary"
                    component={Switch}
                    classes={{ root: "toggle" }}
                  />
                </div>
              </div>
              <div className="cookiePreferences__field">
                <div>
                  {formatMessage({
                    id: "Cookie.Preferences.Form.Functional.Label",
                  })}
                  <div className="cookiePreferences__field__description gutter-top--half">
                    {formatMessage({
                      id: "Cookie.Preferences.Form.Functional.Description",
                    })}
                  </div>
                </div>
                <div>
                  <Field
                    color="primary"
                    name="functionalCookies"
                    component={Switch}
                    classes={{ root: "toggle" }}
                    checked={functionalCookiesValue}
                    onChange={handleFunctionalCookieToggle}
                    defaultChecked={functionalCookieInitialValue}
                  />
                </div>
              </div>
            </DialogContent>
            <ModalFormActionContainer>
              <ModalFormAction
                direction="right"
                onClick={handleClosePreferences}
              >
                {formatMessage({ id: "Common.Cancel" })}
              </ModalFormAction>
              <ModalFormAction color="primary" onClick={submitForm}>
                {formatMessage({ id: "Common.Save" })}
              </ModalFormAction>
            </ModalFormActionContainer>
          </FormikProvider>
        );
      }}
    </ModalForm>
  );

  return (
    <>
      {showConsent && renderConsent()}
      {showPreferences && renderPreferences()}
    </>
  );
};

export default CookieConsent;
