/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React from 'react';

import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';

import { getInfo } from '@utils/apis/login';

import Loader from '@art/LoaderWhite.svg';

import Grid from '@material-ui/core/Grid';
// @ts-ignore
import { Button, FormikInput } from 'dremio-ui-lib';

import { setCookie, deleteCookie, getCookie } from '@utils/cookieUtils';
import { AUTH_METHOD, COOKIE_CONSENT, USERNAME, ORG_ID } from '@constants/cookieConstants';

type EmailFormProps = {
  disabled: boolean;
  handleLoginInfo: (response: any) => void;
}

const EmailForm = (props: EmailFormProps) => {
  const { handleLoginInfo, disabled } = props;
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'Login.Error.EmailValidation' }))
      .required(formatMessage({ id: 'Login.Error.EmailRequired' }))
  });

  const handleSubmit = async ({ email: formEmail }: { email: string }) => {
    try {
      const response = await getInfo(formEmail);
      setUsernameCookie(formEmail);
      handleLoginInfo(response);
    } catch (error: any) {
      handleLoginInfo(error.response);
    }
  };

  const setUsernameCookie = (email: string) => {
    const cookieConsent = getCookie(COOKIE_CONSENT) === 'true';
    const currentCookieEmail = getCookie(USERNAME);
    if (currentCookieEmail !== email) {
      deleteCookie(AUTH_METHOD);
      deleteCookie(ORG_ID);
    }
    cookieConsent && setCookie(USERNAME, email, 31);
  };

  return (
    <Grid container>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Grid item xs={6} classes={{ root: 'gutter-right--double' }}>
            <Form>
              <Field
                id='email'
                type='email'
                name='email'
                disabled={isSubmitting}
                label={formatMessage({ id: 'Login.Labels.Email' })}
                component={FormikInput}
                autoFocus
              />
              <Button
                color='primary'
                disabled={disabled || isSubmitting}
                dataQa='submit-button'
              >
                {isSubmitting ? (
                  <img
                    src={Loader}
                    className='login__icon login__spinner'
                    alt={formatMessage({ id: 'Common.Loading' })}
                  />
                ) : (
                  formatMessage({ id: 'Login.Labels.ContinueButton' })
                )}
              </Button>
            </Form>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default EmailForm;
