/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

import GoogleIcon from "@art/g-normal.png";
import MicrosoftIcon from "@art/microsoft-logo.svg";
import GitHubIcon from "@art/github-logo.png";
import AzureADIcon from "@art/azureAD.svg";
import OktaIcon from "@art/okta.svg";

export const LOCAL = "LOCAL";
export const GITHUB = "GITHUB";
export const GOOGLE = "GOOGLE";
export const MICROSOFT = "MICROSOFT";
export const AZURE_AD = "AZURE_AD";
export const OKTA = "OKTA";

export const ICONS = {
  GOOGLE: GoogleIcon,
  GITHUB: GitHubIcon,
  MICROSOFT: MicrosoftIcon,
  AZURE_AD: AzureADIcon,
  OKTA: OktaIcon,
};

export const DISPLAY_NAME = {
  GOOGLE: "Google",
  GITHUB: "GitHub",
  MICROSOFT: "Microsoft",
  AZURE_AD: "Microsoft Entra ID",
  OKTA: "Okta",
};

export const CONNECTIONS = {
  GOOGLE,
  GITHUB,
  MICROSOFT,
  AZURE_AD,
  OKTA,
};
